import BackgroundBlock from "../components/background-block"
import Layout from "../components/layout"
import SVGIMG from "../components/svg-img"

import HomeMDX from "../content/home"

import React from "react"
import styled from "@emotion/styled"

export default () => {
  return (
    <Layout title="Mary Monsted">
      <StyledBlock src="home-top-banner" minHeight="320px" position="center">
        <SVGIMG containerClassName="home-title" src="home-title.svg" maxWidth="466px" />
      </StyledBlock>
      <HomeMDX />
    </Layout>
  )
}

const StyledBlock = styled(BackgroundBlock)`
  .home-title {
    margin: 0 auto;
    padding: 0 15px;
    position: relative;
    top: 265px;
    max-width: 466px !important;
  }
`;
