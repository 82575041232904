import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Col, Row } from "react-bootstrap";
import Content from "../components/content";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Content className="py-5 text-left white-home-bg" mdxType="Content">
      <Row mdxType="Row">
        <Col className="center-content" mdxType="Col">
          <h1>{`Inspirations from a Lifetime`}</h1>
          <br />
          <p>{`These piano selections are chosen to motivate people of all ages. The message is to enjoy the music and to hear samples of piano music from a variety of composers who have inspired me over my lifetime. Music is the spirit of our soul. It moves the soul and makes room to rejuvenate, comfort, bring peace, and joy into our lives. Within this musical album, I encourage the listener to choose the composer that speaks to their soul at the moment, then pursue researching and knowing more of their other compositions. Hearing one composition by Bach may set the listener on a path of inspiration to performances, creation, or just a sense of peace in one’s soul.`}</p>
          <p>{`Being of Norwegian descent, Greig is a natural inclination, but Chopin and Brahms are also my favorites. From a very young age, I experienced the “warm” musical impression on my heart. All thanks to my church choir director, Avis Dieseth, my piano teacher Hazel Van Dyk, the head of the musical department at Luther College, Weston Noble, my mom Joan Runningen, and my dad, Raymond “Ray” Runningen.`}</p>
          <p>{`Each in their own way has motivated me to personalize piano music in the preschool curriculum for 35 years, in Pineville Presbyterian Church Services in Pass Christian, MS for 39 years, in teaching piano lessons to all ages for 28 years, and in sharing music within my community, and family. Musical Gumbo LLC has two CDs now: “Miss Mary’s Musical Gumbo,” stories, songs, and chants for children two to seven years, and “Classical Piano Music” for all ages. Soon there will be four, adding Jazz Piano Music, and Christmas Piano Music. Music is the universal language. Enjoy it by yourself or with a friend.`}</p>
        </Col>
      </Row>
    </Content>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      